/*eslint-disable */
class RequestInterface {
    constructor(method, url, url_params, body) {
        if(this.constructor == 'RequestInterface') {
            throw new Error("interface cannot be instantiated")
        }
    }

    get version() {
        throw new Error("Abstract method must be implements on concrete")
    }

    //puedes enviarla vacia
    set version(val) {
        throw new Error("Abstract method must be implements on concrete")
    }

    get method() {
        throw new Error("Abstract method must be implements on concrete")
    }

    get url() {
        throw new Error("Abstract method must be implements on concrete")
    }

    get baseURL() {
        throw new Error("Abstract method must be implements on concrete")
    }

    get url_params() {
        throw new Error("Abstract method must be implements on concrete")
    }

    get body() {
        throw new Error("Abstract method must be implements on concrete")
    }

    set method(val) {
        throw new Error("Abstract method must be implements on concrete")
    }

    set url(val) {
        throw new Error("Abstract method must be implements on concrete")
    }

    set baseURL(val) {
        throw new Error("Abstract method must be implements on concrete")
    }

    set url_params(val) {
        throw new Error("Abstract method must be implements on concrete")
    }

    set body(val) {
        throw new Error("Abstract method must be implements on concrete")
    }

    get response_type() {
        throw new Error("Abstract method must be implements on concrete")
    }
    
    set response_type (val) {
        throw new Error("Abstract method must be implements on concrete")
    }

    setToken(val) {
        throw new Error("Abstract method must be implements on concrete")
    }

    setHeader(key, val) {
        throw new Error("Abstract method must be implements on concrete")
    }

    send() {
        throw new Error("Abstract method must be implements on concrete")
    }
}

export { RequestInterface }