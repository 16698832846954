/*eslint-disable */
class AuthInterface {
    constructor(profile_type) {
        if(this.constructor == 'AuthInterface') {
            throw new Error("interface cannot be instantiated")
        }
    }

    getRoute() {
        throw new Error("Abstract method must be implements on concrete")
    }

    login(email, password) {
        throw new Error("Abstract method must be implements on concrete")
    }

    loginGoogle() {
        throw new Error("Abstract method must be implements on concrete")
    }

    logout() {
        throw new Error("Abstract method must be implements on concrete")
    }
}

export { AuthInterface }