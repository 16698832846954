import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"pa-0"},[_c(VAvatar,{attrs:{"size":"36px"}},[_c('img',{attrs:{"alt":"Logo","src":_vm.getLogo(_vm.actual_port)}})])],1),_c(VCol,{staticClass:"pa-0"},[_c(VToolbarTitle,{staticClass:"ml-5"},[_vm._v(_vm._s(_vm.actual_port.name))])],1),(_vm.ports.length > 1)?_c(VCol,{staticClass:"pa-0"},[_c(VMenu,{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-chevron-down")])],1)]}}],null,false,1643245521)},[_c(VList,_vm._l((_vm.ports_without_actual_port),function(port){return _c(VListItem,{key:port.uuid,on:{"click":function($event){return _vm.changeAccount(port.uuid)}}},[_c(VListItemTitle,[_vm._v(_vm._s(port.name))])],1)}),1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }