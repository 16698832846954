import { RequestInterface } from "@/services/interfaces/RequestInterface";
import axios from "axios";
import store from '@/store';
import { Profile } from "@/services/Profile";

class Request extends RequestInterface {
  #method;
  #url;
  #base_url;
  #url_params;
  #body;
  #response_type;
  #timeout;
  #version
  #headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  constructor(method, url, url_params = {}, body = {}) {
    super(method, url, url_params, body);
    this.#method = method.toUpperCase();
    this.#url = url;
    this.#base_url = process.env.VUE_APP_SERVER_URL;
    this.#url_params = url_params;
    this.#body = body;
    this.#response_type = "json";
    this.#timeout = process.env.VUE_APP_TIME_OUT_CONNECTION;
    this.#version = "V1/"
  }

  get version() {
    return this.#version;
  }

  set version(val) {
    if (val !== "") {
      this.#version = val + "/"
    }

    this.#version = val;
  }

  get method() {
    return this.#method;
  }

  set method(val) {
    this.#method = val.toUpperCase();
  }

  get url() {
    return this.#url;
  }

  set url(val) {
    this.#url = val;
  }

  get base_url() {
    return this.#base_url;
  }

  set base_url(val) {
    this.#base_url = val;
  }

  get url_params() {
    return this.#url_params;
  }

  set url_params(val) {
    this.#url_params = val;
  }

  get body() {
    return this.#body;
  }

  set body(val) {
    this.#body = val;
  }

  get response_type() {
    return this.#response_type;
  }

  set response_type(val) {
    this.#response_type = val;
  }

  setToken(token) {
    this.#headers["Authorization"] = `Bearer ${token}`;
  }

  setHeader(key, val) {
    this.#headers[key] = val;
  }

  getHeaders() {
    var profile = new Profile();
    var token = String(profile.token);
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
  }

  send() {
    var profile = new Profile();
    if (profile.isLogged()) {
      this.setToken(profile.token);
    }

    // https://github.com/axios/axios#request-config
    return axios({
      //url: '/user',
      url: this.#version + this.url,
      method: this.method,
      // `baseURL` will be prepended to `url` unless `url` is absolute.
      // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
      // to methods of that instance.
      //baseURL: 'https://some-domain.com/api/',
      baseURL: this.base_url,
      timeout: this.#timeout,
      data: this.body,
      params: this.url_params,
      // `maxContentLength` defines the max size of the http response content in bytes allowed in node.js
      maxContentLength: 2000,
      responseType: this.response_type,
      headers: this.#headers,
    }).then((res) => {
      return res.data;
    })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          store.dispatch('logoutAction');
        }
        // Retornar el error para que el `catch` sepa que hubo un error
        return Promise.reject(error);
      });
  }
}

export { Request };
