<template>
    <div>
        <v-row align="center" justify="center">
            <v-col class="pa-0">
                <v-avatar size="36px">
                    <img alt="Logo" :src="getLogo(actual_port)">
                </v-avatar>
            </v-col>
            <v-col class="pa-0">
                <v-toolbar-title class="ml-5">{{ actual_port.name }}</v-toolbar-title>
            </v-col>
            <v-col class="pa-0" v-if="ports.length > 1">
                <v-menu left bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="port in ports_without_actual_port" :key="port.uuid"
                            @click="changeAccount(port.uuid)">
                            <v-list-item-title>{{ port.name }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <!--v-row>
            <v-col class="d-flex justify-center" cols="6" v-for="port in ports" :key="port.uuid">
                <v-card width="250" @click="changeAccount(port.uuid)">
                    <v-img contain height="200" :src="getLogo(port)"></v-img>
                    <div class="py-5 px-3 text-h6 text-center"> {{ port.name }}</div>
                </v-card>
            </v-col>
        </!--v-row-->
    </div>
</template>

<script>
import { LocalStorage } from "@/services/LocalStorage";
import { Profile } from '@/services/Profile';
import { Auth } from '@/services/Auth';
export default {
    beforeMount() {
        this.ports = this.localStorage.get("related_ports")
        this.actual_port = this.ports.find((port) => port.id === this.profile.getProfileParam('port_id'))
        this.ports_without_actual_port = this.ports.filter((port) => port.id !== this.profile.getProfileParam('port_id'))
    },
    data: function () {
        return {
            ports: [],
            ports_without_actual_port: [],
            actual_port: {},
            localStorage: new LocalStorage(),
            profile: new Profile(),
            auth: new Auth('worker')
        }
    },
    methods: {
        changeAccount(port_uuid) {
            this.auth.changeAccount(port_uuid).then(() => {
                this.$router.go(0);
            })
        },
        getLogo(port) {
            if (!port.logo) {
                return require("@/assets/port/default_logo.png");
            }

            return port.logo
        }
    }
}
</script>