<template>
  <div>
    <v-card flat color="transparent">
      <v-img :src="require('@/assets/contact.webp')"></v-img>
      <v-card-text class=" pa-5">
        {{ $t('contact1') }}: <br /><br />
        <b>Whatsapp: +34 643 552 052</b>
        <br /> <b>Email: info@searchmarina.com</b><br /><br /> {{ $t('contact2') }}<br />

      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    contact_email: process.env.VUE_APP_CONTACT_EMAIL,
  }),
}
</script>