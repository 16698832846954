import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('country-flag',{attrs:{"country":_vm.getFlag(),"size":"normal"}})],1)]}}])},[_c(VList,_vm._l((_vm.available_languages),function(n){return _c(VListItem,{key:n.lang_code,on:{"click":function($event){return _vm.changeLang(n.lang_code)}}},[_c(VListItemTitle,[_c('country-flag',{attrs:{"country":n.flag,"size":"normal"}})],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }