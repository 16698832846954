import Vue from 'vue'
import { i18n } from '@/locales/i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import CountryFlag from 'vue-country-flag'
import LoaderVue from './components/page/Loader.vue'
import AlertMessage from "@/components/page/AlertMessage.vue"
import LogRocket from 'logrocket';
import "./main.css";
//import './registerServiceWorker'
Vue.component('country-flag', CountryFlag)
Vue.component('AlertMessage', AlertMessage)
Vue.component('loader', LoaderVue)
Vue.config.productionTip = false
store.dispatch("chargeProfileData");

//en vue3 se hace con provide/inject
Vue.prototype.$textFieldProps = {
  'persistent-hint': true,
  'persistent-placeholder': true,
  flat: true,
  color: "primary",
  'background-color': "#F1F6FB",
  filled: true,
  solo: false,
  rounded: true
};

if (process.env.NODE_ENV === 'production') {

  LogRocket.init('5rcaro/search-marina', {
    dom: {
      textSanitizer: true,
      inputSanitizer: true,
    },
  });
}


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')

