/*eslint-disable */
class RestInterface {
    constructor(model_name) {
        if(this.constructor == 'RestInterface') {
            throw new Error("interface cannot be instantiated")
        }
    }

    get model_name() {
        throw new Error("Abstract method must be implements on concrete")
    }

    set model_name(val) {
        throw new Error("Abstract method must be implements on concrete")
    }
    
    show(id, relations = [], overwrite_model_name = "") {
        throw new Error("Abstract method must be implements on concrete")
    }

    index(filter_params = [], relations = [], overwrite_model_name = "") {
        throw new Error("Abstract method must be implements on concrete")
    }

    create(params, overwrite_model_name = "") {
        throw new Error("Abstract method must be implements on concrete")
    }

    update(id, params, overwrite_model_name = "") {
        throw new Error("Abstract method must be implements on concrete")
    }

    delete(id, overwrite_model_name = "") {
        throw new Error("Abstract method must be implements on concrete")
    }
}

export { RestInterface }