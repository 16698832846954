import { Rest } from "./Rest";
import { LocalIndex } from "./LocalIndex";
import DateHelper from "./DateHelper";

class Booking extends Rest {
  #localIndex;
  constructor() {
    super("booking")
    this.#localIndex = new LocalIndex();
  }

  async indexFromLocalStorage(relations = []) {
    return await this.#localIndex.indexFromLocalStorage("booking", this, relations)
  }

  /*indexUser(filter_params = [], relations = [], port_id) {
    return super.index(filter_params, relations, 'port-zone/port/' + port_id)
  }*/

  getDashboardBookings() {
    return super.index([], [], 'booking/getDashboardBookings')
  }

  index(filter_params = [], relations = []) {
    return super.index(filter_params, relations, "booking/worker")
    //supongo que en index habra que cambiar la ruta para el usuario
  }

  showWorker(id, relations = []) {
    return super.show(id, relations, "booking/worker")
  }

  show(id, relations = []) {
    return super.show(id, relations)
  }

  update(id, params) {
    return super.update(id, params, "")
  }

  arrived(id, params) {
    return super.update(id, params, "booking/arrived")
  }

  departured(id, params) {
    return super.update(id, params, "booking/departured")
  }

  newBookingFromWorker(params) {//zona
    return super.create(params, "booking/createBookingFromWorker")
  }

  create(params) {//zona
    return super.create(params, "")
  }

  delete(id) {
    return super.delete(id)
  }

  addEndBooking(id, params) {
    return super.update(id, params, "booking/addEndBooking")
  }

  temporaryDeparture(id, params) {
    return super.update(id, params, "booking/temporaryDeparture")
  }

  bookingOverlapInArray(booking, bookingArray) {
    return bookingArray.some((b) => this.bookingsOverlap(booking, b))
  }

  bookingIsNow(booking) {
    return (DateHelper.dateBetweenDates(DateHelper.today(), booking.date_ini, booking.date_end) || (booking.is_subscription_active && DateHelper.firstDateAfterOrEqualSecondDate(DateHelper.today(), booking.date_ini)))
  }

  bookingsOverlap(booking, saved_booking) {
    //DA IGUAL SI SE SOLAPAN EN EL PASADO!!!
    if (booking.id === saved_booking.id) {
      return false; //son el mismo y no se pueden solapar
    }

    if (booking.is_subscription_active && saved_booking.is_subscription_active) {
      return true;
    }
    //AQUI COMPROBAMOS QUE DESCARTAMOS EL PASADO CON LA PRIMERA PREMISA
    const saved_booking_date_ini = this.getDateIniOrToday(saved_booking);
    const booking_date_ini = this.getDateIniOrToday(booking);

    if (saved_booking.is_subscription_active) {
      return DateHelper.firstDateAfterSecondDate(booking.date_end, saved_booking_date_ini)
    }

    if (booking.is_subscription_active) {
      return /*DateHelper.firstDateAfterSecondDate(saved_booking.date_end, DateHelper.today()) &&*/ DateHelper.firstDateAfterSecondDate(saved_booking.date_end, booking_date_ini)
    }

    return (
      DateHelper.firstDateAfterOrEqualSecondDate(booking_date_ini, saved_booking_date_ini) &&
      DateHelper.firstDateAfterSecondDate(saved_booking.date_end, booking_date_ini)
    )
      ||
      (
        DateHelper.firstDateAfterSecondDate(booking.date_end, saved_booking_date_ini) &&
        DateHelper.firstDateAfterOrEqualSecondDate(saved_booking.date_end, booking.date_end)
      )
      ||
      (
        DateHelper.firstDateAfterOrEqualSecondDate(saved_booking_date_ini, booking_date_ini) &&
        DateHelper.firstDateAfterOrEqualSecondDate(booking.date_end, saved_booking.date_end)
      )
  }

  getDateIniOrToday(booking) {
    const today = DateHelper.today();
    if (DateHelper.firstDateAfterSecondDate(booking.date_end, today) && DateHelper.firstDateAfterSecondDate(today, booking.date_ini)) { //Si la reserva ya ha acabado, no nos importa
      return today;
    }

    return booking.date_ini;
  }
}

export { Booking };
