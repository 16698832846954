<template>
    <v-overlay z-index="200" :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</template>
<script>
export default {
    name: "Loader",
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>