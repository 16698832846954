<script>
export default {
    name: "ButtonDialog",
    props: {
        dialog: {
            type: Boolean,
            default: false,
        },
        buttonColor: {
            type: String,
            default: "primary"
        },
        buttonVariant: {
            type: String,
            default: "outlined"
        },
        title: {
            type: String,
            default: ""
        },
        subtitle: {
            type: String,
            default: ""
        },
        confirmation: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        setResult(val) {
            return val ? this.$emit("confirm") : this.$emit("cancel");
        }
    }
}
</script>
<template>
    <v-dialog transition="dialog-bottom-transition" v-model="dialog" activator="parent" max-width="600px">
        <v-card flat color="blue-grey darken-3" dark>
            <v-card-title v-if="title" class="grey--text text--lighten-1 mb-4">
                {{ title }}
            </v-card-title>
            <v-card-subtitle class="grey--text text--lighten-1 mb-4">
                {{ subtitle }}
            </v-card-subtitle>
            <v-card-text class="text-right">
                <v-btn v-if="!confirmation" class="mt-6" color="success" depressed @click="setResult(false)">{{
        $t('close') }}</v-btn>
                <v-btn v-if="confirmation" class="mt-6 mr-3" color="grey" outlined @click="setResult(false)">{{
        $t('Cancel') }}</v-btn>
                <v-btn v-if="confirmation" class="mt-6" color="success" depressed @click="setResult(true)">{{ $t('b90')
                    }}</v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>