/*eslint-disable */
class ProfileInterface {
    constructor() {
        if(this.constructor == 'ProfileInterface') {
            throw new Error("interface cannot be instantiated")
        }
    }

    getItemsMenu() {
        throw new Error("Abstract method must be implements on concrete")
    }

    getAllProfiles() {
        throw new Error("Abstract method must be implements on concrete")
    }

    isLogged(){
        throw new Error("Abstract method must be implements on concrete")
    }

    get token() {
        throw new Error("Abstract method must be implements on concrete")
    }

    set token(token) {
        throw new Error("Abstract method must be implements on concrete")
    }

    get last_profile_type() {
        throw new Error("Abstract method must be implements on concrete")
    }

    get profile_type() {
        throw new Error("Abstract method must be implements on concrete")
    }

    set profile_type(type) {
        throw new Error("Abstract method must be implements on concrete")
    }

    get local_profile() {
        throw new Error("Abstract method must be implements on concrete")
    }

    set local_profile(profile) {
        throw new Error("Abstract method must be implements on concrete")
    }

    getProfileParam(param_name) {
        throw new Error("Abstract method must be implements on concrete")
    }

    async changeEmail(emailParams){
        throw new Error("Abstract method must be implements on concrete")
    }

    async changePassword(passwordParams){
        throw new Error("Abstract method must be implements on concrete")
    }

    async updateServerProfile(profile) {
        throw new Error("Abstract method must be implements on concrete")
    }

    async getServerProfile() {
        throw new Error("Abstract method must be implements on concrete")
    }

}

export { ProfileInterface }