import { AuthInterface } from "./interfaces/AuthInterface";
import { Request } from "./Request";
import { ProfileAbstraction } from "./ProfileAbstraction";
import store from "@/store";

class Auth extends AuthInterface {
  //#prefix_route;
  #profile_type;
  #profile;
  //#request;

  constructor(profile_type) {
    super();
    //this.#request = new Request();
    this.#profile_type = profile_type;
    this.#profile = new ProfileAbstraction();
    this.#profile.profile_type = profile_type;
  }

  login(email, password) {
    var route = this.getRoute() + "login";
    var request = new Request(
      "POST",
      route,
      {},
      { email: email, password: password }
    );
    return request.send().then((res) => {
      store.dispatch("loginAction", res.data);
      return res;
    });
  }

  changeAccount(port_uuid) {
    let route = this.getRoute() + "change-account/" + port_uuid;
    let request = new Request(
      "GET",
      route,
      {},
    );
    return request.send().then((res) => {
      store.dispatch("changeAccountAction", res.data);
      return res;
    });
  }

  getRoute() {
    return "auth-" + this.#profile_type + "/";
  }

  logout() {
    var route = this.getRoute() + "logout";
    var request = new Request("GET", route);
    return request.send().finally(() => {
      store.dispatch("logoutAction")
      return true
    });
  }

  async restorePassword(email) {
    const route = this.getRoute() + "password/restore";
    const request = new Request("POST", route, {}, { email: email });
    return request.send();
  }

  async resetPassword(params) {
    const route = "password/change";
    const request = new Request("POST", route, {}, params);
    request.version = ""
    return request.send();
  }
}

export { Auth };
