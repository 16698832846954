import { Rest } from "./Rest";
import { LocalIndex } from "@/services/LocalIndex";
import DateHelper from "./DateHelper";

class ElectricityBooking extends Rest {
  #localIndex;
  constructor() {
    super("electricity-booking")
    this.#localIndex = new LocalIndex();
  }

  async indexFromLocalStorage(relations = []) {
    return await this.#localIndex.indexFromLocalStorage("electricity_booking", this, relations)
  }

  index(filter_params = [], relations = []) {
    return super.index(filter_params, relations)
  }

  show(id, relations = []) {
    return super.show(id, relations)
  }

  update(id, params) {
    return super.update(id, params, "")
  }

  create(params) {
    return super.create(params, "")
  }

  delete(id) {
    return super.delete(id)
  }

  bookingOverlapInArray(booking, bookingArray) {
    return bookingArray.some((b) => this.bookingsOverlap(booking, b))
  }

  bookingIsNow(booking) {
    return (DateHelper.dateBetweenDates(DateHelper.today(), booking.date_ini, booking.date_end) || (booking.automatically_renew && DateHelper.firstDateAfterOrEqualSecondDate(DateHelper.today(), booking.date_ini)))
  }

  bookingsOverlap(booking, saved_booking) {
    if (booking.uuid === saved_booking.uuid) {
      return false //si es el mismo no se puede sueperponer
    }

    if (booking.automatically_renew && saved_booking.automatically_renew) {
      return true;
    }

    if (saved_booking.automatically_renew) {
      return DateHelper.firstDateAfterSecondDate(booking.date_end, saved_booking.date_ini)
    }

    if (booking.automatically_renew) {
      return DateHelper.firstDateAfterSecondDate(saved_booking.date_end, booking.date_ini)
    }

    return (DateHelper.firstDateAfterOrEqualSecondDate(booking.date_ini, saved_booking.date_ini) && DateHelper.firstDateAfterSecondDate(saved_booking.date_end, booking.date_ini))
      || (DateHelper.firstDateAfterSecondDate(booking.date_end, saved_booking.date_ini) && DateHelper.firstDateAfterOrEqualSecondDate(saved_booking.date_end, booking.date_end))
      || (DateHelper.firstDateAfterOrEqualSecondDate(saved_booking.date_ini, booking.date_ini) && DateHelper.firstDateAfterOrEqualSecondDate(booking.date_end, saved_booking.date_end))
  }
}

export { ElectricityBooking };
