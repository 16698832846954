import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticClass:"pa-5"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('b95')))]),_c(VCardText,[_c(VDataTable,{attrs:{"items-per-page":_vm.itemsPerPage,"headers":_vm.headers,"items":_vm.notifications,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:page":_vm.handlePageChange},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.text))]),_c('td',[_vm._v(_vm._s(_vm.getTextDate(item.read_at)))]),_c('td',[(!item.read_at)?_c(VChip,{attrs:{"small":"","color":"orange"},on:{"click":function($event){return _vm.itemToRead(item)}}},[_vm._v(" "+_vm._s(_vm.$t('b98'))),_c(VIcon,{attrs:{"small":"","color":"success","right":""}},[_vm._v("mdi-check")])],1):_c(VChip,{attrs:{"small":""},on:{"click":function($event){return _vm.itemToRead(item)}}},[_vm._v(" "+_vm._s(_vm.$t('b99'))+" "),_c(VIcon,{attrs:{"small":"","right":"","color":"red"}},[_vm._v("mdi-close")])],1)],1)])]}}])})],1)],1),_c('ButtonDialog',{attrs:{"dialog":_vm.confirm_dialog,"title":_vm.$t('a78'),"confirmation":""},on:{"cancel":function($event){_vm.confirm_dialog = false},"confirm":_vm.confirmed}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }