<template>
  <v-menu left bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <country-flag :country="getFlag()" size='normal' />
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="n in available_languages" :key="n.lang_code" @click="changeLang(n.lang_code)">
        <v-list-item-title>
          <country-flag :country="n.flag" size='normal' />
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { i18nActions } from '@/locales/i18n'
export default {
  name: "LanguageFlag",
  data() {
    return {
      selected_language: i18nActions.getLanguage(),
      available_languages: i18nActions.getAvailableLanguages(),
    };
  },
  methods: {
    getFlag() {
      return i18nActions.getFlagForLanguage(this.selected_language)
    },
    changeLang(lang) {
      this.selected_language = lang
      i18nActions.setLanguage(lang)
      this.$router.go(0);
    },
  },
}
</script>