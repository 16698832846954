import { Rest } from "./Rest";
import { LocalIndex } from "@/services/LocalIndex";

class BerthGroup extends Rest {
  #localIndex;
  constructor() {
    super("berth-group")
    this.#localIndex = new LocalIndex();
  }

  async indexFromLocalStorage(relations = []) {
    return await this.#localIndex.indexFromLocalStorage("berth_group", this, relations)
  }

  index(filter_params = [], relations = []) {
    return super.index(filter_params, relations)
  }

  show(id, relations = []) {
    return super.show(id, relations)
  }

  update(id, params) {
    return super.update(id, params, "")
  }

  create(params) {
    return super.create(params, "")
  }

  delete(id) {
    return super.delete(id)
  }
}

export { BerthGroup };
