import { Rest } from "./Rest";
import { LocalIndex } from "@/services/LocalIndex";

class ElectricityTower extends Rest {
  #localIndex;
  constructor() {
    super("electricity-tower")
    this.#localIndex = new LocalIndex();
  }

  async indexFromLocalStorage(relations = []) {
    return await this.#localIndex.indexFromLocalStorage("electricity_tower", this, relations)
  }

  index(filter_params = [], relations = []) {
    return super.index(filter_params, relations)
  }

  async indexAllAndSaveInLocal(filter_params = [], relations = []) {
    return await this.#localIndex.indexAllAndSaveInLocal("electricity_tower", this, filter_params, relations)
  }

  show(id, relations = []) {
    return super.show(id, relations)
  }

  update(id, params) {
    return super.update(id, params, "")
  }

  create(params) {
    return super.create(params, "")
  }

  delete(id) {
    return super.delete(id)
  }

  addBerth(id, params) {
    return super.update(id, params, "electricity-tower/add-berth")
  }

  deleteBerth(id, berth_id) {
    return super.delete("", "electricity-tower/" + id + "/delete-berth/" + berth_id)
  }
}

export { ElectricityTower };
