<template>
  <v-footer padless>
    <v-card color="#1D1B31" width="100%" flat tile class="text-center">
      <!--v-card-text>
        <div class="d-flex justify-center">
          <div class="white--text" v-for="(icon, i) in icons" :key="i">
            <v-btn @click="setPage(icon.page)" class="mx-4 white--text" icon>
              <v-icon size="24px">
                {{ icon.icon }}
              </v-icon>
            </v-btn>
            <br />
            <div>{{ icon.text }}</div>
          </div>
        </div>
      </!--v-card-text-->

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} —
        <span class="text-subtitle-2">SEArchMarina.com® </span>
        {{ $t("c19") }} <br />
        <a class="mr-5 text-caption text-decoration-none white--text" @click="setPage('CookiesPolicy')">
          {{ $t("cp1") }}
        </a> ·
        <a class="mx-5 text-caption text-decoration-none white--text" @click="setPage('LegalDisclaimer')">
          {{ $t("ld1") }}
        </a> · <a class="mx-5 text-caption text-decoration-none white--text" @click="setPage('TermsAndConditions')">
          {{ $t("tc2") }}
        </a> · <a class="ml-5 text-caption text-decoration-none white--text" @click="contact_dialog = true">
          {{ $t("Contact") }}
        </a>
      </v-card-text>
    </v-card>
    <WindowDialog :dialog="contact_dialog" :subtitle='$t("Contact")' @close="contact_dialog = false">
      <Contact />
    </WindowDialog>
    <v-dialog eager v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card flat tile>
        <div class="pa-5">
          <v-btn icon color="primary" @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-card flat tile class="ml-5 mt-5">
            <About v-if="page === 'About'" />
            <!--Contact v-if="page === 'Contact'" /-->
            <CookiesManagement v-if="page === 'CookiesManagement'" />
            <CookiesPolicy v-if="page === 'CookiesPolicy'" />
            <FAQ v-if="page === 'FAQ'" />
            <PrivacyPolicy v-if="page === 'PrivacyPolicy'" />
            <Support v-if="page === 'Support'" />
            <TermsAndConditions v-if="page === 'TermsAndConditions'" />
            <LegalDisclaimer v-if="page === 'LegalDisclaimer'" />
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </v-footer>
</template>
<script>
import About from "@/components/page/info/About.vue";
//import Contact from "@/views/Contact.vue";
import CookiesManagement from "@/components/page/info/CookiesManagement.vue";
import CookiesPolicy from "@/components/page/info/CookiesPolicy.vue";
import FAQ from "@/components/page/info/FAQ.vue";
import PrivacyPolicy from "@/components/page/info/PrivacyPolicy.vue";
import Support from "@/components/page/info/Support.vue";
import TermsAndConditions from "@/components/page/info/TermsAndConditions.vue";
import LegalDisclaimer from "@/components/page/info/LegalDisclaimer.vue";
import WindowDialog from "@/components/page/WindowDialog";
import Contact from "@/components/page/Contact";
export default {
  name: "Footer",
  components: {
    About,
    Contact,
    CookiesManagement,
    CookiesPolicy,
    FAQ,
    PrivacyPolicy,
    Support,
    TermsAndConditions,
    LegalDisclaimer,
    WindowDialog
  },
  data: function () {
    return {
      dialog: false,
      contact_dialog: false,
      page: "",
      icons: [
        /*{
          icon: "mdi-help-circle",
          text: "FAQ",
          page: "FAQ",
        },
        {
          icon: "mdi-chat",
          text: this.$i18n.t("Contact"),
          page: "Contact",
        },
        /*{
          icon: "mdi-information",
          text: this.$i18n.t("Support"),
          page: "Support",
        },*/
      ],
    };
  },
  methods: {
    setPage(page) {
      this.page = page;
      this.dialog = true;
    },
  },
};
</script>
