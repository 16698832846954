<template>
    <v-dialog persistent :eager="eager" v-model="dialog" :fullscreen="fullscreen" :max-width="max_width">
        <v-card tile class="bg-gray">
            <v-card-title v-if="title">
                {{ title }}
            </v-card-title>
            <v-card-subtitle
                class="pt-5 text-uppercase primary white--text d-flex justify-space-between align-center">{{
        subtitle
    }}
                <v-btn icon color="white" @click="close"><v-icon>mdi-close</v-icon></v-btn>

            </v-card-subtitle>
            <v-card-text class="mx-auto pt-5 dialog_container">
                <slot></slot>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "WindowDialog",
    props: {
        dialog: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: ""
        },
        subtitle: {
            type: String,
            default: ""
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        max_width: {
            type: Number,
            default: 600
        },
        eager: {
            type: Boolean,
            default: false,
        },

    },
    methods: {
        close() {
            this.$emit("close")
        }
    }
}
</script>