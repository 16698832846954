import { Booking } from "./Booking";
import { ElectricityBooking } from "./ElectricityBooking";
import { Berth } from "./Berth";
import { Ship } from "./Ship";
import { ElectricityTower } from "./ElectricityTower";
import { BerthGroup } from "./BerthGroup";
//import { BerthType } from "./BerthType";
import { Port } from "./Port";
import { PortService } from "./PortService";
import { PortServiceType } from "./PortServiceType";
import { LocalStorage } from "@/services/LocalStorage";
import { Profile } from '@/services/Profile';

class LoadDataInLocal {
    #localStorage;
    #port_id
    constructor() {
        this.#localStorage = new LocalStorage();
    }

    async beginToReload() {
        this.stopToReload()
        this.#localStorage.set('each30minutes', setInterval(() => {
            this.each30Minutes()
        }, 180000))//180000
        this.#localStorage.set('eachDay', setInterval(() => {
            this.eachDay()
        }, 86400000))
        await this.each30Minutes()
        await this.eachDay()
    }

    async each30Minutes() {
        if (new Profile().isLogged()) {
            await Promise.all([
                new Booking().indexFromLocalStorage(),
                new ElectricityBooking().indexFromLocalStorage(),
                new Berth().indexFromLocalStorage(),
                new Ship().indexFromLocalStorage(),
            ])
        } else {
            this.stopToReload()
        }
    }

    async eachDay() {
        if (new Profile().isLogged()) {
            this.#port_id = new Profile().local_profile.port_id
            const res = await Promise.all([
                new Port().show(this.#port_id, ["portZones"]),
                new ElectricityTower().indexAllAndSaveInLocal([], ['plugs', 'berths']),
                new BerthGroup().indexFromLocalStorage(),
                new PortService().indexFromLocalStorage(),
                new PortServiceType().indexFromLocalStorage(),
                //new BerthType().indexFromLocalStorage(),
            ])
            this.#localStorage.set('port', res[0].data)
        } else {
            this.stopToReload()
        }
    }

    stopToReload() {
        clearInterval(this.#localStorage.get('each30minutes'))
        clearInterval(this.#localStorage.get('eachDay'))
    }

}

export { LoadDataInLocal };