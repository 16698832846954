import { i18n } from '@/locales/i18n'
const DateHelper = {

  months: [
    i18n.t("January"),
    i18n.t("February"),
    i18n.t("March"),
    i18n.t("April"),
    i18n.t("May"),
    i18n.t("June"),
    i18n.t("July"),
    i18n.t("August"),
    i18n.t("September"),
    i18n.t("October"),
    i18n.t("November"),
    i18n.t("December"),
  ],
  days: [
    i18n.t("Sunday"),
    i18n.t("Monday"),
    i18n.t("Tuesday"),
    i18n.t("Wednesday"),
    i18n.t("Thursday"),
    i18n.t("Friday"),
    i18n.t("Saturday"),
  ],
  getMonthName(val) {
    return this.months[val - 1];
  },
  getDayName(val) {
    return this.days[val - 1];
  },
  formatDate(date) {
    return date.getFullYear() +
      "-" +
      this.addZeroIfNeeded(date.getMonth() + 1) +
      "-" +
      this.addZeroIfNeeded(date.getDate())
  },
  addDaysToDate(days, date) {
    var d = new Date(date);
    d.setDate(d.getDate() + days);
    return this.formatDate(d)
  },
  firstDateAfterOrEqualSecondDate(date1, date2) {
    var d1 = new Date(date1);
    var d2 = new Date(date2);
    return d1.getTime() >= d2.getTime()
  },
  firstDateAfterSecondDate(date1, date2) {
    var d1 = new Date(date1);
    var d2 = new Date(date2);
    return d1.getTime() > d2.getTime()
  },
  dateBetweenDates(date, date_ini, date_end) {
    var d = new Date(date);
    var d_ini = new Date(date_ini);
    var d_end = new Date(date_end);
    return d.getTime() >= d_ini.getTime() && d.getTime() <= d_end.getTime();
  },
  getNumberOfDays(date_ini, date_end) {
    var d_ini = new Date(date_ini).getTime();
    var d_end = new Date(date_end).getTime();
    return (d_end - d_ini) / (1000 * 60 * 60 * 24);
  },
  getDatesInRange(date_ini, date_end, included_last_day) {
    var res = []
    if (this.firstDateAfterOrEqualSecondDate(date_ini, date_end)) {
      return res;
    }

    var d_ini = new Date(date_ini);
    var d_end = new Date(date_end);
    while (d_end > d_ini) {
      res.push(d_ini.getFullYear() + '-' + this.addZeroIfNeeded(d_ini.getMonth() + 1) + '-' + this.addZeroIfNeeded(d_ini.getDate()));
      d_ini.setDate(d_ini.getDate() + 1);
    }

    if (included_last_day) {
      res.push(d_ini.getFullYear() + '-' + this.addZeroIfNeeded(d_ini.getMonth() + 1) + '-' + this.addZeroIfNeeded(d_ini.getDate()));
    }

    return res;
  },
  dayMonthFormatSmall(date) {
    if (!date.length) {
      return "-";
    }

    var d = new Date(date.substring(0, 10));
    if (!d) {
      return "-";
    }

    const day = d.getDate();
    const monthName = this.months[d.getMonth()].substring(0, 3);
    const year = d.getFullYear().toString().substring(2);
    return `${day} ${monthName} '${year}`;
  },
  dayMonthFormat(date) {
    if (!date.length) {
      return "-";
    }

    var d = new Date(date);
    if (!d) {
      return "-";
    }

    const day = d.getDate();
    const dayName = this.days[d.getDay()];
    const monthName = this.months[d.getMonth()];
    return `${dayName}, ${day} ${monthName}`;
  },
  firstDayOfPreviousMonths(day, months) {
    const first_days = [];
    const actual_date = new Date(day);

    for (let i = 1; i <= months; i++) {
      const date = new Date(actual_date.getFullYear(), actual_date.getMonth() - i, 1);
      const date_with_format = `${date.getFullYear()}-${this.addZeroIfNeeded(date.getMonth() + 1)}-${this.addZeroIfNeeded(date.getDate())}`
      first_days.unshift(date_with_format); // Agregar al principio del array
    }

    return first_days;
  },
  lastDayOfMonth(month, year) {
    const date = new Date(year, month, 0);
    return `${date.getFullYear()}-${this.addZeroIfNeeded(date.getMonth() + 1)}-${date.getDate()}`
  },
  addZeroIfNeeded(val) {
    var res = String(val);
    if (val < 10) {
      res = "0" + res;
    }

    return res;
  },
  formatMonth(val) {
    var m = parseInt(val) + 1; //pq los formatos van de 0 a 11
    return this.addZeroIfNeeded(m);
  },
  today() {
    var d = new Date();
    return (
      d.getFullYear() +
      "-" +
      this.formatMonth(d.getMonth()) +
      "-" +
      this.addZeroIfNeeded(d.getDate())
    );
  },
  nowInIso() {
    return new Date(new Date().toUTCString()).toISOString()
  },
  now() {
    const dt = new Date();
    const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
    return `${dt.getFullYear()}-${padL(dt.getMonth() + 1)}-${padL(
      dt.getDate()
    )} ${padL(dt.getHours())}:${padL(dt.getMinutes())}:${padL(
      dt.getSeconds()
    )}`;
  },
  checkIfValidDate(d) {
    return new Date(d) > 0
  },
  randomDate(start_date, end_date) {
    var start = new Date(start_date)
    var end = new Date(end_date)
    var res = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    return this.formatDate(res)
  },
  dateFormatsArray() {
    return [
      "YYYY-MM-DD",
      "DD-MM-YYYY",
      "MM-DD-YYYY",
      "YYYY/MM/DD",
      "DD/MM/YYYY",
      "MM/DD/YYYY",
      "YYYY.MM.DD",
      "DD.MM.YYYY",
      "MM.DD.YYYY",
      "YYYY MM DD",
      "YYYY-M-D",
      "D-M-YYYY",
      "M-D-YYYY",
      "YYYY/M/D",
      "D/M/YYYY",
      "M/D/YYYY",
      "YYYY.M.D",
      "D.M.YYYY",
      "M.D.YYYY",
      "YY-MM-DD",
      "DD-MM-YY",
      "MM-DD-YY",
      "YY/MM/DD",
      "DD/MM/YY",
      "MM/DD/YY",
      "YY.MM.DD",
      "DD.MM.YY",
      "MM.DD.YY",
      "YY MM DD"
    ];
  },
  //example 2-5-97, DD-MM-YY
  changeDateFromFormatToIso(date, format) {
    var formatPieces = format.split(/[-/ .]/); // Separar el formato en partes

    // Obtener índices de los componentes de la fecha en el formato
    var indexDay = formatPieces.indexOf('DD') !== -1 ? formatPieces.indexOf('DD') : formatPieces.indexOf('D');
    var indexMonth = formatPieces.indexOf('MM') !== -1 ? formatPieces.indexOf('MM') : formatPieces.indexOf('M');
    var indexYear = formatPieces.indexOf('YYYY') !== -1 ? formatPieces.indexOf('YYYY') : formatPieces.indexOf('YY');

    var datePieces = date.split(/[-/ .]/); // Separar la fecha en partes

    var day = datePieces[indexDay];
    if (day.length === 1)
      day = '0' + day
    var month = datePieces[indexMonth];
    if (month.length === 1)
      month = '0' + month
    var year = datePieces[indexYear];
    // Ajustar el año si es de 2 dígitos y mayor a 50
    if (year.length === 2) {
      if (parseInt(year) > 50) {
        year = '19' + year;
      } else {
        year = '20' + year;
      }
    }

    // Construir la fecha en el formato aceptado por el constructor del objeto Date
    return year + '-' + month + '-' + day;
  },
  showDateInBooking(date_ini, date_end, is_subscription) {
    if (is_subscription) {
      return i18n.t("b21", { date_ini: DateHelper.dayMonthFormatSmall(date_ini) })
    }

    return DateHelper.dayMonthFormatSmall(date_ini) + ' | ' +
      DateHelper.dayMonthFormatSmall(date_end)
  },
  getFirstDayOfNextMonth(dateString) {
    let date = new Date(dateString);
    let nextMonth = date.getMonth() + 1;
    let year = date.getFullYear();
    if (nextMonth > 11) {
      nextMonth = 0;
      year++;
    }

    let firstDayOfNextMonth = new Date(year, nextMonth, 1);

    let yyyy = firstDayOfNextMonth.getFullYear();
    let mm = String(firstDayOfNextMonth.getMonth() + 1).padStart(2, '0');
    let dd = String(firstDayOfNextMonth.getDate()).padStart(2, '0');

    return `${yyyy}-${mm}-${dd}`;
  }
};
export default DateHelper;
