
/*eslint-disable */
class StorageInterface {
    constructor() {
        if(this.constructor == 'StorageInterface') {
            throw new Error("interface cannot be instantiated")
        }
    }

    set(key, value) {
        throw new Error("Abstract method must be implements on concrete")
    }

    get() {
        throw new Error("Abstract method must be implements on concrete")
    }

    getForIndex(index) {
        throw new Error("Abstract method must be implements on concrete")
    }

    getKeys() {
        throw new Error("Abstract method must be implements on concrete")
    }

    getLength() {
        throw new Error("Abstract method must be implements on concrete")
    }

    getSupport() {
        throw new Error("Abstract method must be implements on concrete")
    }

    remove(key) {
        throw new Error("Abstract method must be implements on concrete")
    }

    removeAll() {
        throw new Error("Abstract method must be implements on concrete")
    }

    getAll() {
        throw new Error("Abstract method must be implements on concrete")
    }
}

export { StorageInterface }