import { Rest } from "./Rest";
import { v4 as uuidv4 } from 'uuid';
import { LocalIndex } from "./LocalIndex";

class Ship extends Rest {
  #localIndex;
  constructor() {
    super("ship")
    this.#localIndex = new LocalIndex();
  }

  async indexFromLocalStorage(relations = []) {
    return await this.#localIndex.indexFromLocalStorage("ship", this, relations)
  }

  index(filter_params = [], relations = []) {
    return super.index(filter_params, relations)
  }

  show(id, relations = []) {
    return super.show(id, relations)
  }

  update(id, params) {
    return super.update(id, params)
  }

  create(params) {
    return super.create(params)
  }

  delete(id) {
    return super.delete(id)
  }

  indexDocuments(ship_id) {
    return super.index([], [], "ship/documents/" + ship_id) //ojo que es documents, no document para que no haya conflictos en las rutas
  }

  addDocument(document, params, ship_id) {
    let formData = new FormData();
    formData.append('document_uuid', uuidv4());
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        formData.append(key, params[key]);
      }
    }

    formData.append("document", document);
    return super.create(formData, "ship/document/" + ship_id, true)
  }

  getDocument(id, relations = []) {
    return super.show(id, relations, "ship/document")
  }

  validateDocument(uuid, params) {
    return super.update(uuid, params, "ship/document/validate")
  }

  deleteDocument(uuid) {
    return super.delete(uuid, "ship/document")
  }

  indexPortShipDocuments(ship_id) {
    return super.index([], [], "ship/port-ship-documents/" + ship_id) //ojo que es documents, no document para que no haya conflictos en las rutas
  }

  addPortShipDocument(document, params, ship_id) {
    let formData = new FormData();
    formData.append('document_uuid', uuidv4());
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        formData.append(key, params[key]);
      }
    }

    formData.append("document", document);
    return super.create(formData, "ship/port-ship-document/" + ship_id, true)
  }

  getPortShipDocument(id, relations = []) {
    return super.show(id, relations, "ship/port-ship-document")
  }

  deletePortShipDocument(uuid) {
    return super.delete(uuid, "ship/port-ship-document")
  }

}

export { Ship };
