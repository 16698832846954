import Vue from 'vue'
import Vuex from 'vuex'
import { LocalStorage } from "@/services/LocalStorage";
import { LoadDataInLocal } from "@/services/LoadDataInLocal";
import { Auth } from "@/services/Auth";
import router from '@/router';

Vue.use(Vuex)
var profile_no_logged = "no_logged";
//var profiles = ["user", "worker", "distributor", "super-admin"];
var localStorage = new LocalStorage();
var loadDataInlocal = new LoadDataInLocal()

export default new Vuex.Store({
  state: {
    profile: "",
    profile_type: profile_no_logged,
    last_profile_type: "user",
    related_ports: [],
    auth: false,
    token: "",
    berths: [],
    berthsById: {},
  },
  getters: {
    logged: (state) => state.auth,
    profile_type: (state) => state.profile_type,
    last_profile_type: (state) => state.last_profile_type,
    profile: (state) => state.profile,
    token: (state) => state.token,
    related_ports: (state) => state.related_ports,
    berthsFromBerthGroup: state => berth_group_id => {
      return state.berths.filter(berth => berth.berth_group_id === berth_group_id);
    },
    getBerths: (state) => state.berths,
    getBerth: state => id => {
      return state.berths.find(berth => berth.id === id);
    },
    bookings: (state) => state.berths.flatMap(berth => {
      return berth.bookings.map(booking => {
        return Object.assign({}, booking, { berth: berth });
      });
    })
  },
  mutations: {
    setBerths(state, berths) {
      state.berths = berths;
    },
    updateBerth(state, berth) {
      const berthInState = state.berths.find(b => b.id === berth.id);
      if (berthInState) {
        const actualBerth = JSON.parse(JSON.stringify(berthInState))
        const updatedBerth = {
          ...actualBerth,
          ...berth
        }
        updatedBerth.bookings = actualBerth.bookings
        state.berthInState = updatedBerth;
      }
    },
    /*addBooking(state, booking) {
      const berthIndex = state.berths.findIndex(b => b.id === booking.berth_id);
      if (berthIndex) {
        state.berths[berthIndex].bookings.push(booking);
        Vue.set(state.berths, berthIndex, state.berths[berthIndex])
        //Vue.set(berth, "bookings", berth.bookings)
        //berth.bookings.push(booking);
      }
    },*/
    addBooking(state, booking) {
      const berthIndex = state.berths.findIndex(b => b.id === booking.berth_id);
      if (berthIndex !== -1) {
        state.berths[berthIndex].bookings.push(booking);
        Vue.set(state.berths, berthIndex, state.berths[berthIndex])
      }
    },
    deleteBooking(state, booking) {
      const berthIndex = state.berths.findIndex(b => b.bookings.some(b => b.id === booking.id));
      const bookingIndex = state.berths[berthIndex].bookings.findIndex(r => r.id === booking.id);
      if (bookingIndex !== -1) {
        state.berths[berthIndex].bookings.splice(bookingIndex, 1);
        Vue.set(state.berths, berthIndex, state.berths[berthIndex])
      }
    },
    updateBooking(state, booking) {
      const actualBerthIndex = state.berths.findIndex(b => b.bookings.some(b => b.id === booking.id));
      const destinyBerthIndex = state.berths.findIndex(b => b.id === booking.berth_id);
      const bookingIndex = state.berths[actualBerthIndex].bookings.findIndex(r => r.id === booking.id);
      if (actualBerthIndex !== destinyBerthIndex) {
        state.berths[actualBerthIndex].bookings.splice(bookingIndex, 1);
        state.berths[destinyBerthIndex].bookings.push(booking);
        Vue.set(state.berths, actualBerthIndex, state.berths[actualBerthIndex])
        Vue.set(state.berths, destinyBerthIndex, state.berths[destinyBerthIndex])
      } else {
        // Actualizar reserva en la misma habitación
        state.berths[actualBerthIndex].bookings.splice(bookingIndex, 1, booking);
        Vue.set(state.berths, actualBerthIndex, state.berths[actualBerthIndex])
      }
    },

    set_profile_at_reload(state) {
      var token = localStorage.get("token");
      state.token = token;
      state.auth = !!token;
      state.profile = localStorage.get("profile");
      state.profile_type = localStorage.get("profile_type");
      state.last_profile_type = localStorage.get("last_profile_type");
      state.related_ports = localStorage.get("related_ports")
      loadDataInlocal.beginToReload()
    },
    set_data_in_local(state) {
      loadDataInlocal.beginToReload()
    },
    set_profile(state, profile) {
      state.profile = profile;
      localStorage.set("profile", profile);
    },
    set_profile_type(state, profile_type) {
      state.profile_type = profile_type;
      localStorage.set("profile_type", profile_type);
    },
    set_last_profile_type(state, profile_type) {
      state.last_profile_type = profile_type;
      localStorage.set("last_profile_type", profile_type);
    },
    set_token(state, token) {
      state.auth = true;
      state.token = token;
      localStorage.set("token", token);
    },
    set_related_ports(state, data) {
      if (data.related_ports) {
        state.related_ports = data.related_ports
        localStorage.set("related_ports", data.related_ports)
      }
    },
    logout(state) {
      loadDataInlocal.stopToReload()
      localStorage.removeAll();
      //localStorage.remove("token");
      localStorage.set("profile_type", profile_no_logged);
      //localStorage.remove("berth")
      //localStorage.remove("berth_group")
      //localStorage.remove("booking")
      state.profile_type = profile_no_logged;
      state.auth = false;
      state.token = "";
      state.berthGroups = {
        //cada berthGroup tiene un array de berths y cada uno un array de bookings
      }
    },
    remove_user(state) {
      localStorage.remove("profile");
      state.profile = "";
    },
  },
  actions: {
    updateBerth({ commit }, berth) {
      commit('updateBerth', berth)
    },
    setBerthsWithBookings({ commit }, berths) {
      commit('setBerths', berths)
    },
    updateBooking({ commit }, booking) {
      commit('updateBooking', booking);
    },
    addBooking({ commit }, booking) {
      commit('addBooking', booking);
    },
    deleteBooking({ commit }, booking) {
      commit('deleteBooking', booking);
    },
    updateProfileAction({ commit }, profile) {
      commit("set_profile", profile);
    },
    changeAccountAction({ commit }, data) {
      commit("logout");
      commit("set_profile", data.profile);
      commit("set_profile_type", data.profile_type);
      commit("set_last_profile_type", data.profile_type);
      commit("set_token", data.access_token);
      commit("set_related_ports", data);
      commit("set_data_in_local");

    },
    loginAction({ commit }, data) {
      commit("set_profile", data.profile);
      commit("set_profile_type", data.profile_type);
      commit("set_last_profile_type", data.profile_type);
      commit("set_token", data.access_token);
      commit("set_related_ports", data);
    },
    registerAction({ commit }, profile) {
      commit("set_profile", profile);
      commit("set_profile_type", profile_no_logged);
    },
    logoutAction({ commit }) {
      commit("logout");
      router.push('/login');
      router.go(0);
    },
    chargeProfileData({ commit }) {
      commit("set_profile_at_reload");
    },
    removeProfile({ commit }) {
      commit("remove_profile");
    },
    changeProfileType({ commit }, profile_type) {
      commit("set_profile_type", profile_type);
    },
  },
  modules: {},
})
