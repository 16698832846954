import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"transition":"dialog-bottom-transition","activator":"parent","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"flat":"","color":"blue-grey darken-3","dark":""}},[(_vm.title)?_c(VCardTitle,{staticClass:"grey--text text--lighten-1 mb-4"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c(VCardSubtitle,{staticClass:"grey--text text--lighten-1 mb-4"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]),_c(VCardText,{staticClass:"text-right"},[(!_vm.confirmation)?_c(VBtn,{staticClass:"mt-6",attrs:{"color":"success","depressed":""},on:{"click":function($event){return _vm.setResult(false)}}},[_vm._v(_vm._s(_vm.$t('close')))]):_vm._e(),(_vm.confirmation)?_c(VBtn,{staticClass:"mt-6 mr-3",attrs:{"color":"grey","outlined":""},on:{"click":function($event){return _vm.setResult(false)}}},[_vm._v(_vm._s(_vm.$t('Cancel')))]):_vm._e(),(_vm.confirmation)?_c(VBtn,{staticClass:"mt-6",attrs:{"color":"success","depressed":""},on:{"click":function($event){return _vm.setResult(true)}}},[_vm._v(_vm._s(_vm.$t('b90')))]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }