import { StorageInterface } from "@/services/interfaces/StorageInterface.js";

class LocalStorage extends StorageInterface {
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
    // localStorage.key = value;
    // localStorage[key] = value;
  }

  get(key) {
    let result = localStorage.getItem(key);
    if (!(result === null || result === "undefined")) {
      return JSON.parse(result);
    }

    return null;
  }

  getForIndex(index) {
    return localStorage.key(index);
  }

  getKeys() {
    const items = this.getAll();
    const keys = [];
    for (let index = 0; index < items.length; index++) {
      keys.push(items[index].key);
    }
    return keys;
  }

  getLength() {
    return localStorage.length;
  }

  getSupport() {
    return typeof Storage !== "undefined";
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  removeAll() {
    localStorage.clear();
  }

  getAll() {
    const arr = []; // Definir el conjunto de datos
    for (let i = 0; i < localStorage.length; i++) {
      // Obtener el índice de la clave a partir de 0
      const getKey = localStorage.key(i);
      const getVal = localStorage.getItem(getKey || "");
      arr[i] = {
        key: getKey,
        val: getVal,
      };
    }

    return arr;
  }
  
}

export { LocalStorage }
