import { Rest } from "./Rest";

class Port extends Rest {
  constructor() {
    super("port")
  }

  index(filter_params = [], relations = []) {
    return super.index(filter_params, relations)
  }

  show(id, relations = []) {
    return super.show(id, relations)
  }

  update(id, params) {
    return super.update(id, params)
  }

  create(params) {
    return super.create(params)
  }

  delete(id) {
    return super.delete(id)
  }

  uploadLogo(logo) {
    let formData = new FormData();
    formData.append("logo", logo);
    return super.create(formData, "port/logo", true)
  }

  uploadMapImage(map_image) {
    let formData = new FormData();
    formData.append("map", map_image);
    return super.create(formData, "port/map", true)
  }

  getPayments() {
    return super.index([], [], "port/payments")
  }

}

export { Port };
