import { RestInterface } from "./interfaces/RestInterface";
import { Request } from "./Request";

class Rest extends RestInterface {
  #model_name;
  constructor(model_name) {
    super(model_name);
    this.#model_name = model_name;
  }

  setRelations(rel) {
    if (rel.length > 0) {
      return { with: rel.toString() };
    }

    return {};
  }

  changeModelName(name) {
    if (name === "") {
      return this.#model_name;
    }

    return name;
  }

  show(id, relations = [], overwrite_model_name = "") {
    var url = this.changeModelName(overwrite_model_name)
    let request = new Request(
      "GET",
      `${url}/${id}`,
      this.setRelations(relations)
    );
    return request.send().then((res) => {
      return res;
    });
  }

  index(filter_params = [], relations = [], overwrite_model_name = "") {
    var url = this.changeModelName(overwrite_model_name)
    var url_params = {
      ...filter_params,
      ...this.setRelations(relations),
    };
    let request = new Request("GET", `${url}`, url_params);
    return request.send().then((res) => {
      return res;
    });
  }

  create(params, overwrite_model_name = "", is_file = false) {
    var url = this.changeModelName(overwrite_model_name)
    let request = new Request("POST", `${url}`, {}, params);
    if (is_file) {
      request.setHeader("Content-Type", "multipart/form-data")
    }

    return request.send().then((res) => {
      return res;
    });
  }

  update(id, params, overwrite_model_name = "", is_file = false) {
    var url = this.changeModelName(overwrite_model_name)
    let request = new Request("PUT", `${url}/${id}`, {}, params);
    if (is_file) {
      request.setHeader("Content-Type", "multipart/form-data")
    }

    return request.send().then((res) => {
      return res;
    });
  }

  delete(id, overwrite_model_name = "") {
    var url = this.changeModelName(overwrite_model_name)
    let request = new Request("DELETE", `${url}/${id}`);
    return request.send().then((res) => {
      return res;
    });
  }
}

export { Rest };
