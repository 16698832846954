import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueI18n from 'vue-i18n'
import en from "@/locales/en.json"
import es from "@/locales/es.json"
import es_vuetify from 'vuetify/lib/locale/es'
import en_vuetify from 'vuetify/lib/locale/en'
import { LocalStorage } from "@/services/LocalStorage";

Vue.use(Vuetify)
Vue.use(VueI18n)

export const messages = {
    en: Object.assign(en, {
      $vuetify: en_vuetify,
    }),
    es: Object.assign(es, {
      $vuetify: es_vuetify,
    }),
}

const available_languages = ['en', 'es'] 
const default_language = "en"
const flags_and_languages = [
  {lang_code: 'es', flag: 'es'},
  {lang_code: 'en', flag: 'gb'},
  {lang_code: 'de', flag: 'de'},
  {lang_code: 'fr', flag: 'fr'},
  {lang_code: 'it', flag: 'it'},
]


var localStorage = new LocalStorage();

  /*
si quisieramos hacer traducciones por apartados o compuestas:
const messages = {
  es: {
    hello: {
      world: 'Hola Mundo!'
    }
  },
  en: {
    hello: {
      world: 'Hello World!'
    }
  }
}

Y luego al usarlo <h1>{{ $t('hello.world') }}</h1>
  */
function defaultLanguage() {
    var lang = localStorage.get('language') || window.navigator.language.split('-')[0]
    if( ! available_languages.includes(lang)) {
      return default_language;
    }
    
    return lang
}

export const i18nActions = {
    setLanguage(lang) {
        localStorage.set('language', lang)
        i18n.locale = lang
    },
    getAvailableLanguages() {
      var res = []
      flags_and_languages.forEach(lang_item => {        
        if(available_languages.includes(lang_item.lang_code.toLowerCase())) {          
            res.push(lang_item)
        }
      })
      
      return res
    },
    getAvailableLanguages() {
      var res = []
      flags_and_languages.forEach(lang_item => {        
        if(available_languages.includes(lang_item.lang_code.toLowerCase())) {          
            res.push(lang_item)
        }
      })
      
      return res
    },
    getLanguage() {
        return defaultLanguage()
    },
    getFlagForLanguage(lang) {
        var item = flags_and_languages.find(lang_item => lang_item.lang_code.toLowerCase() === lang.toLowerCase())
        return item.flag
    }
}

  export const i18n = new VueI18n({
    locale: defaultLanguage(), // set locale
    fallbackLocale: 'en',
    messages, // set locale messages
  })

  export default new Vuetify({
    lang: {
      t: (key, ...params) => i18n.t(key, params),
    },
  })