import { Rest } from "./Rest";
import { LocalIndex } from "./LocalIndex";
import { LocalStorage } from "@/services/LocalStorage";
import { Booking } from "@/services/Booking";

class Berth extends Rest {
  #localIndex;
  #localStorage;
  constructor() {
    super("berth")
    this.#localIndex = new LocalIndex();
    this.#localStorage = new LocalStorage();
  }

  async indexFromLocalStorage(relations = []) {
    return await this.#localIndex.indexFromLocalStorage("berth", this, relations)
  }

  index(filter_params = [], relations = []) {
    return super.index(filter_params, relations)
  }

  show(id, relations = []) {
    return super.show(id, relations)
  }

  update(id, params) {
    return super.update(id, params, "")
  }

  create(params) {//zona
    return super.create(params, "")
  }

  delete(id) {
    return super.delete(id)
  }

  getFreeBerthsForShip(ship, booking) { //solo los amarres
    return this.availableBerthsInGroups(this.getFreeBerths(booking), ship);
  }

  getSelectArrayForFreeBerths(ship, booking) {
    return this.getFreeBerthsForShip(ship, booking).map((berth) => { return { id: berth.id, berth_name: this.getBerthNameAndSize(berth, this.#localStorage.get("berth_group")) } });
  }

  getBusyBerthIdsForBooking(booking) { //interno
    const bookingService = new Booking();
    return this.#localStorage.get("booking")
      .filter(actual_booking => bookingService.bookingsOverlap(actual_booking, booking))
      .map(actual_booking => actual_booking.berth_id);
  }

  getFreeBerths(booking) { //sin filtro por tamaños
    return this.#localStorage.get("berth").filter(berth => !this.getBusyBerthIdsForBooking(booking).includes(berth.id))
  }

  getAvailableBerthGroupIdsForShip(ship) { //interno
    return this.#localStorage.get("berth_group").filter((group) => {
      return Number(ship.length) <= Number(group.max_length) && Number(ship.beam) <= Number(group.max_beam) && Number(ship.draft) <= Number(group.max_draft)
    }).map(group => group.id)
  }

  getBerthNameAndSize(berth, berth_groups) {
    let berth_group = berth_groups.find(group => group.id === berth.berth_group_id)
    return berth.name + ' ---> (' + berth_group.max_length + ' m x ' + berth_group.max_beam + ' m)'
  }

  availableBerthsInGroups(berths, ship) { //interno
    let availableGroupIds = this.getAvailableBerthGroupIdsForShip(ship);
    return berths.filter(berth => !berth.is_closed && availableGroupIds.includes(berth.berth_group_id));
  }

  shipIsBigger(ship, berthGroup) {
    if (Number(berthGroup.rotate) === 0 || Number(berthGroup.rotate) === 180) {
      return Number(ship.beam) > Number(berthGroup.max_beam)
    } else {
      return Number(ship.length) > Number(berthGroup.max_length)
    }
  }

  shipIsDeeper(ship, berthGroup) {
    return Number(ship.draft) > Number(berthGroup.max_draft)
  }

}

export { Berth };
