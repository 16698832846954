import DateHelper from "./DateHelper";
import { LocalStorage } from "@/services/LocalStorage";

class LocalIndex {
  #localStorage;
  constructor() {
    this.#localStorage = new LocalStorage();
  }

  async indexFromLocalStorage(item_name, itemClass, relations = []) {
    var item_date_name = "last_update_" + item_name
    var actual_items = this.#localStorage.get(item_name) || []
    if (!actual_items === null || actual_items.length === 0) {
      const res = await itemClass.index({ limit: 50000 }, relations)
      this.#localStorage.set(item_name, res.data)
    } else {
      var check_date = this.#localStorage.get(item_date_name)
      const res = await itemClass.index({ limit: 50000, show_deleted: true, updated_at: "gt," + check_date }, relations)
      res.data.forEach((item) => {
        var index = actual_items.findIndex(i => i.id === item.id)
        if (index > -1 && item.deleted_at !== null) {
          actual_items.splice(index, 1)
        }

        if (item.deleted_at === null) {
          index > -1 ? actual_items[index] = item : actual_items.push(item)
        }
      })

      this.#localStorage.set(item_name, actual_items)
    }

    this.#localStorage.set(item_date_name, DateHelper.nowInIso())
    return actual_items
  }

  async indexAllAndSaveInLocal(item_name, itemClass, params = [], relations = []) {
    params["limit"] = 5000
    const res = await itemClass.index(params, relations)
    this.#localStorage.set(item_name, res.data)
    return res.data;
  }
}

export { LocalIndex };