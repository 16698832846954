<template>
  <div>
    <v-alert :id="random_id" tile dismissible :value="show" text :type="alert_type" border="left"
      transition="scale-transition">
      {{ alert_text }}
      <ul v-if="show_errors">
        <li v-for="(e, i) in errors" :key="i">
          {{ Array.isArray(e) ? e[0] : e }}
        </li>
      </ul>
    </v-alert>
  </div>
</template>
<script>
export default {
  data() {
    return {
      alert_type: "success", //info, warning, error
      alert_text: "",
      show: false,
      show_errors: false,
      errors: [],
      random_id: Math.random().toString(36).substring(2, 15)
    };
  },
  methods: {
    resetStyle() {
      var el = document.getElementById(this.random_id);
      el.style.display = ""
      this.reset()
    },
    reset() {
      this.show_errors = false;
      this.errors = []
      this.scrollToAlert()
    },
    hide() {
      this.show = false //esta la he añadido la ultima
    },
    scrollToAlert() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      //document.getElementById(this.random_id).scrollIntoView({ block: "start", behavior: "smooth" });
    },
    showInfo(text) {
      this.reset()
      this.alert_type = "info";
      this.alert_text = text;
      this.show = true;
      setTimeout(() => {
        this.show = false;
        this.alert_text = ""
      }, 5000);
    },
    showWarning(text) {
      this.reset()
      this.alert_type = "warning";
      this.alert_text = text;
      this.show = true;
      setTimeout(() => {
        this.show = false;
        this.alert_text = ""
      }, 5000);
    },
    showSuccess(res, text = "") {
      this.reset()
      this.resetStyle()
      this.alert_type = "success";
      if (text.length > 0) {
        this.alert_text = text;
      } else {
        this.alert_text = res.data.message;
      }

      this.show = true;
      setTimeout(() => {
        this.show = false;
        this.alert_text = ""
      }, 5000);
    },
    showError(error, text = "") {
      this.resetStyle()

      this.alert_type = "error";
      if (text.length > 0) {
        this.alert_text = text;
      } else {
        var error_from_axios = error.response.data;
        this.alert_text = error_from_axios.message;
        this.errors = error_from_axios.errors;
        this.show_errors = true;
      }

      this.show = true;

    },
  },
};
</script>
