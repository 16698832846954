<template>
    <div>
        <v-card class="pa-5">
            <v-card-title>{{ $t('b95') }}</v-card-title>
            <v-card-text>
                <v-data-table :items-per-page="itemsPerPage" :headers="headers" :items="notifications"
                    :server-items-length="total" :loading="loading" @update:page="handlePageChange"
                    :footer-props="footerProps">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.text }}</td>
                            <td>{{ getTextDate(item.read_at) }}</td>
                            <td>

                                <v-chip v-if="!item.read_at" small color="orange" @click="itemToRead(item)">
                                    {{ $t('b98') }}<v-icon small color="success" right>mdi-check</v-icon>
                                </v-chip>
                                <v-chip v-else small @click="itemToRead(item)">
                                    {{ $t('b99') }} <v-icon small right color="red">mdi-close</v-icon>
                                </v-chip>
                            </td>

                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <ButtonDialog :dialog="confirm_dialog" :title="$t('a78')" confirmation @cancel="confirm_dialog = false"
            @confirm="confirmed" />
    </div>
</template>
<script>
import { WorkerNotification } from '@/services/WorkerNotification';
import ButtonDialog from '@/components/page/ButtonDialog';
import DateHelper from '@/services/DateHelper';
export default {
    name: "WorkerNotification",
    components: { ButtonDialog },
    data() {
        return {
            loading: false,
            confirm_dialog: false,
            item_to_confirm: {},
            notifications: [],
            notificationService: new WorkerNotification(),
            headers: [
                { text: this.$i18n.t('b96'), value: "text" },
                { text: this.$i18n.t('b97'), value: "read_at" },
                { text: "", value: "actions", sortable: false },
            ],
            total: 0,
            itemsPerPage: 20,
            footerProps: {
                'items-per-page-options': [20],  // Cambia las opciones de paginación aquí
                'show-current-page': true,
                'show-first-last-page': true,
                'items-length': 10,
            },
        }
    },
    beforeMount() {
        setTimeout(() => {
            this.loadMessages(0);
        }, 65000); //para que no se ejecute cuando se carga la pagina
        setInterval(this.loadMessages, 20 * 60 * 1000);
    },
    methods: {
        itemToRead(item) {
            this.confirm_dialog = true
            this.item_to_confirm = item
        },
        confirmed() {
            this.confirm_dialog = false
            this.notificationService.toggleRead(this.item_to_confirm.id)
            this.item_to_confirm.read_at = this.item_to_confirm.read_at === null ? DateHelper.today() : null;
            const index = this.notifications.findIndex(notif => notif.id === this.item_to_confirm.id);
            if (index !== -1) {
                this.notifications[index] = this.item_to_confirm;
                this.unread();
            }
        },
        unread() {
            let unread = this.notifications.filter(notif => !notif.read_at)
            this.$emit('unread', unread.length);
        },
        loadMessages(page = 0) {
            this.notificationService.index({ order_by: "read_at,ASC", page: page })
                .then((res) => {
                    this.notifications = res.data;
                    this.total = res.items
                    this.unread();
                })

        },
        handlePageChange(page) {
            this.loadMessages(page);
        },
        getTextDate(date) {
            return date ? DateHelper.dayMonthFormatSmall(date) : '-'
        }
    }

}
</script>