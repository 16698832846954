import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","eager":_vm.eager,"fullscreen":_vm.fullscreen,"max-width":_vm.max_width},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"bg-gray",attrs:{"tile":""}},[(_vm.title)?_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c(VCardSubtitle,{staticClass:"pt-5 text-uppercase primary white--text d-flex justify-space-between align-center"},[_vm._v(_vm._s(_vm.subtitle)+" "),_c(VBtn,{attrs:{"icon":"","color":"white"},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"mx-auto pt-5 dialog_container"},[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }